import { Banner, Button, Form, FormLayout, Page, Select } from '@shopify/polaris'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isCreateDeclarationTransaction } from 'redux/features/transactions/transactionsSlice'
import useAppDispatch from 'hooks/useAppDispatch'
import FormsForCreateNewTransactionDigital from './formsForCreateNewTransaction/FormsForCreateNewTransactionDigital'
import FormsForCreateNewTransactionGoods from './formsForCreateNewTransaction/FormsForCreateNewTransactionGoods'

const DataStructure = [
	{label: `Goods`, value: 'Goods', key: `1`},
	{label: `Digital`, value: 'Digital', key: `2`},
]

const CreateNewTransaction = ({
	closeHandler,
	data,
	changeVatStr
}) => {
	
	const {fetchingCreateDeclarationError} = useSelector((state) => state.transactions)
	const [changeDataStructure, setChangeDataStructure] = useState('Goods')
	const [formData, setFormData] = useState({})
	const dispatch = useAppDispatch()
	
	const setFormDataHandler = value => setFormData(value)
	
	const handleSubmit = async () => {
		const digitalStatus = changeDataStructure === 'Goods' ? false : true
		const formDataValue = {
			...formData,
			vat_percent: parseInt(changeVatStr.count, 10),
			taxable_jurisdiction: data.customer_country_id,
			if_digital: digitalStatus
		}
		try {
			const res = await dispatch(isCreateDeclarationTransaction(formDataValue))
			if (res.status === 200) closeHandler()
		} catch (e) {
			await dispatch(fetchingCreateDeclarationError(e))
		}
	}
	
	return (
		<Page title={`Transaction Form for ${changeVatStr.count}%`}>
			<Form onSubmit={handleSubmit}>
        <FormLayout style={{paddingBottom: '20px !important'}}>
	        <Select
		        label='Select the data structure to fill in.'
		        options={DataStructure}
		        value={changeDataStructure}
		        onChange={setChangeDataStructure}
	        />
	        {
		        changeDataStructure === 'Goods'
		        &&
		        <FormsForCreateNewTransactionGoods
			        data={data}
			        dispatch={dispatch}
			        setFormDataHandler={setFormDataHandler}
		        />
	        }
	        {
		        changeDataStructure === 'Digital'
		        &&
		        <FormsForCreateNewTransactionDigital
			        data={data}
			        dispatch={dispatch}
			        setFormDataHandler={setFormDataHandler}
		        />
	        }
	        {
		        fetchingCreateDeclarationError &&
		        <Banner status='critical'>
						<p >
							{`${fetchingCreateDeclarationError?.length > 0
								? fetchingCreateDeclarationError?.map(error => `${error?.loc[2]} : ${error?.msg}`)
								: fetchingCreateDeclarationError?.error || ""}`}
						</p >
					</Banner >
	        }
	        <Button
		        submit
		        primary
	        >
					Submit
				</Button >
				</FormLayout >
			</Form >
		</Page >
	)
}

export default CreateNewTransaction
