import {
	ActionList,
	Box,
	Button,
	ButtonGroup,
	Card,
	DataTable,
	Inline,
	Layout,
	Popover,
	Spinner,
	Text
} from '@shopify/polaris'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NoDataMessage from '../../../components/NoDataMessage'
import Pagination from '../../../components/Pagination/Pagination'
import AddIcon from '../../../img/add-white.svg'
import importIMG from '../../../img/import.svg'
import settingsIMG from '../../../img/settings.svg'
import formatRow from '../../../utils/formatRow'
import { DocumentType } from '../../DocumentTemplate/common/constants'
import ConvertToTransactions from '../modals/ConvertToTransactions'
import DeleteInvoiceModal from '../modals/DeleteInvoice'
import ScanFiles from '../modals/ScanFiles'
import UploadFromXero from '../modals/UploadFromXero'
import useFetchDocuments from '../tabs/Invoices/hooks/useFetchDocuments'
import useSelectedRows from '../tabs/Invoices/hooks/useSelectedRows'
import GetColumnsEx from './GetColumnsEx'

const ExemptionTable = () => {
	const [changeInOrOut, setChangeInOrOut] = useState('in');
	const navigate = useNavigate();
	const columns = GetColumnsEx(changeInOrOut)
	// const rows = []
	const [query, setQuery] = useState({
		invoice_type: 'all',
		type: DocumentType.Exemption,
		offset: 0,
		limit: 25
	});
	const { documents, loading, fetchDocuments } = useFetchDocuments(query)
	const { selectedRows, handleSelectAll, handleRowSelect } =
		useSelectedRows(documents);
	
	const rows = formatRow(documents, columns) || []

	return (
		<>
			<Layout.Section>
      <Inline align='space-between'>
        <ButtonGroup segmented>
          <Button
	          primary={changeInOrOut === 'in'}
	          onClick={() => setChangeInOrOut('in')}
          >
            Incoming
          </Button>
          <Button
	          primary={changeInOrOut === 'out'}
	          onClick={() => setChangeInOrOut('out')}
          >
            Outgoing
          </Button>
        </ButtonGroup>
        <Inline>
          <Button
	          primary
	          onClick={() => navigate(`${changeInOrOut === 'in' ? '/documents/exemption/add' : '/documents/exemption/create'}`)}
	          icon={
		          <img
			          src={AddIcon}
			          alt='add'
			          width='15'
			          style={{ marginTop: 4, marginRight: 5 }}
		          />
	          }
          >
            {`${changeInOrOut === 'in' ? 'Add Exemption' : 'Create certificate'}`}
          </Button>
	        <Popover
		        active={false}
		        preferredPosition='below'
		        fullWidth
		        activator={
			        <button
				        fullWidth
				        className={'button-upload-options-style'}
			        >
                <img
	                src={importIMG}
	                alt='upload'
	                width='15'
	                style={{marginTop: 4, marginRight: 5}}
                />
                Upload options
              </button >
		        }
		        // onClose={() => setActive(null)}
	        >
            <ActionList
	            actionRole='menuitem'
	            items={[
		            {
			            content: "Upload from Xero",
			            // onAction: () =>
				            // handleActionDialogsOpen(actionDialogsKeys.uploadFromXero),
		            },
		            {
			            content: "Scan files",
			            // onAction: () =>
				            // handleActionDialogsOpen(actionDialogsKeys.scanFiles),
		            },
		            {
			            content: "Convert to transactions",
			            // onAction: () =>
				          //   handleActionDialogsOpen(actionDialogsKeys.convertToTransactions),
		            },
	            ]}
            />
          </Popover >
          
          <Popover
	          // active={active === "bulk-actions-popover"}
	          activator={
		          <Button
			          // onClick={() =>
				        //   setActive(
					      //     active === "bulk-actions-popover"
						    //       ? null
						    //       : "bulk-actions-popover"
				        //   )
			          // }
			          icon={
				          <img
					          src={settingsIMG}
					          alt='edit'
					          width='15'
					          style={{ marginTop: 2, marginRight: 3 }}
				          />
			          }
		          >
                Group Actions
              </Button>
	          }
	          // onClose={() => setActive(null)}
          >
            <ActionList
	            actionRole='menuitem'
	            items={[
		            {
			            content: "Delete",
			            // onAction: () => handleBulkAction("delete"),
		            },
		            {
			            content: "Mark as Paid",
			            // onAction: () => handleBulkAction("markPaid"),
		            },
		            {
			            content: "Mark as Sent",
			            // onAction: () => handleBulkAction("markSent"),
		            },
		            {
			            content: "Approve",
			            // onAction: () => handleBulkAction("approve"),
		            },
	            ]}
            />
          </Popover>
        </Inline>
      </Inline>
      <br />
      <Card>
        {false ? (
	        <Card.Section>
            <Spinner />
          </Card.Section>
        ) : (
	        <>
            <DataTable
	            columnContentTypes={Array(columns.length).fill("text")}
	            headings={columns.map(({ header }) => (
		            <Text fontWeight='semibold'>{header.label}</Text>
	            ))}
	            rows={rows}
            />
		        {rows.length === 0 && (
			        <NoDataMessage
				        description={"To see documents in the list, first add them."}
				        buttonText={"Add Documents from templates"}
			        />
		        )}
          </>
        )}
      </Card>
				
				{rows.length > 0 && (
					<Box padding='4'>
          <Pagination
	          // total={countDocuments}
	          // current={pagination.page}
	          // pageSize={defaultLimit}
	          // onChange={(current) => onPageChange(current)}
          />
        </Box>
				)}
				
				<DeleteInvoiceModal
					// isOpen={actionDialogs[actionDialogsKeys.deleteVatInvoice].open}
					// data={actionDialogs[actionDialogsKeys.deleteVatInvoice].cellData}
					// onClose={() =>
					// 	handleActionDialogsClose(actionDialogsKeys.deleteVatInvoice)
					// }
					// afterDelete={fetchDocuments}
				/>

      <UploadFromXero
	      // isOpen={actionDialogs[actionDialogsKeys.uploadFromXero].open}
	      // data={actionDialogs[actionDialogsKeys.uploadFromXero].cellData}
	      // onClose={() =>
		    //   handleActionDialogsClose(actionDialogsKeys.uploadFromXero)
	      // }
	      // afterUpload={fetchDocuments}
      />
      <ConvertToTransactions
	      // isOpen={actionDialogs[actionDialogsKeys.convertToTransactions].open}
	      // data={actionDialogs[actionDialogsKeys.convertToTransactions].cellData}
	      // onClose={() =>
		    //   handleActionDialogsClose(actionDialogsKeys.convertToTransactions)
	      // }
	      // afterUpload={fetchDocuments}
      />

      <ScanFiles
	      // isOpen={actionDialogs[actionDialogsKeys.scanFiles].open}
	      // data={actionDialogs[actionDialogsKeys.scanFiles].cellData}
	      // onClose={() => handleActionDialogsClose(actionDialogsKeys.scanFiles)}
	      // afterScan={fetchDocuments}
      />
    </Layout.Section>
  </ >
	)
}

export default ExemptionTable