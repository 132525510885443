import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "hooks/useClickOutside";
import { Button, TextField } from "@shopify/polaris";
import { formatDate } from "utils/dates";

import calendarIMG from "img/calendar.svg";
import clearIMG from "img/close-circle.svg";

import "./index.scss";

const Datepicker = (props) => {
  const { t } = useTranslation();
  const {
    onChange = () => {},
    isLimited = true, // Флаг для управления ограничениями
    label = t("datepicker.dateRange"),
    prefix = <img src={calendarIMG} alt="icon calendar" style={{ marginTop: 5 }} />,
    start,
    end,
    value,
    allowRange = true,
    placeholder,
    datepickerProps,
    isClearable = false,
    customFormatDate = formatDate,
    alignment = "left",
    labelDisabled,
    maxDatePeriodYear = 1
  } = props;

  const [active, setActive] = useState(false);
  const [renderDate, setRenderDate] = useState("");
  const [transactionDatetime, setTransactionDatetime] = useState(null);
  const [startDate, setStartDate] = useState(
    start ? new Date(start) : new Date()
  );
  const [endDate, setEndDate] = useState(end ? new Date(end) : null);
  
  const minDate = isLimited ? new Date(new Date().setFullYear(new Date().getFullYear() - 5)) : null;
  const maxDate = isLimited ? new Date(new Date().setFullYear(new Date().getFullYear() + maxDatePeriodYear)) : null;
  
  const pickerRef = useClickOutside(() => {
    setActive(false);
  });

  useEffect(() => {
    if (allowRange) {
      if (start && end) {
        setRenderDate(`${customFormatDate(start)} - ${customFormatDate(end)}`);
      }
    } else {
      if (value) {
        setRenderDate(customFormatDate(value));
      } else {
        setRenderDate("");
      }
    }
  }, [allowRange, start, end, value, customFormatDate]);

  const handleChange = (dates) => {
    if (allowRange) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      onChange(start, end); // Update both start and end dates
    } else {
      setStartDate(dates);
      onChange(dates); // Call the onChange with the selected date
    }

    // Update the render date
    setRenderDate(
      allowRange
        ? `${customFormatDate(dates[0])} - ${customFormatDate(dates[1])}`
        : customFormatDate(dates)
    );

    // Закрыть календарь после выбора даты
    setActive(false);
  };

  // Handle transactionDatetime change
  useEffect(() => {
    if (transactionDatetime) {
      const parsedDate = new Date(transactionDatetime);
      if (!isNaN(parsedDate)) {
        setStartDate(parsedDate);
      }
    }
  }, [transactionDatetime]);

  const handleDateChange = (start) => {
    if (start) {
      setTransactionDatetime(start.toISOString()); // Keep the full ISO string
    }
  };

  const onClear = () => {
    if (allowRange) {
      onChange(null, null);
    } else {
      onChange(null);
    }
    setRenderDate(""); // Clear the displayed date
    setStartDate(new Date()); // Reset to today or default
    setEndDate(null);
  };

  const handleToday = () => {
    const today = new Date();
    setStartDate(today);
    setEndDate(null);
    onChange(today);
    setRenderDate(customFormatDate(today));
    setActive(false); // Close the calendar
  };

  // Handle input changes
  const handleInputChange = (value) => {
    setRenderDate(value); // Update displayed date value
    const parsedDate = new Date(value); // Try to parse the input as a date
    if (!isNaN(parsedDate)) {
      setStartDate(parsedDate); // Set parsed date to startDate
      onChange(parsedDate); // Apply the new date
    }
  };

  return (
    <div className="Datepicker" ref={pickerRef}>
      <TextField
        type="text"
        label={labelDisabled ? "" : label}
        prefix={prefix}
        value={renderDate || ""}
        onFocus={() => setActive(true)}
        placeholder={placeholder}
        onChange={handleInputChange} // Allow keyboard input
        connectedRight={
          isClearable && (
            <img
              src={clearIMG}
              onClick={onClear}
              alt="clear"
              style={{
                width: "18px",
                height: "18px",
                marginTop: 8,
                cursor: "pointer",
              }}
            />
          )
        }
      />
      {active && (
        <div className="Datepicker-Wrapper" style={{ [alignment]: 0 }}>
          <ReactDatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            inline
            selectsRange={allowRange}
            formatWeekDay={(nameOfDay) =>
              new Date(nameOfDay).toLocaleString("en-us", {
                weekday: "short",
              })[0]
            }
            showYearDropdown
            dropdownMode="select"
            minDate={minDate}
            maxDate={maxDate}
            {...datepickerProps}
          />
          <Button onClick={handleToday} primary>
            {t("Today")}
          </Button>
        </div>
      )}
    </div>
  );
};

Datepicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  prefix: PropTypes.node,
  start: PropTypes.string,
  end: PropTypes.string,
  value: PropTypes.string,
  allowRange: PropTypes.bool,
  placeholder: PropTypes.string,
  datepickerProps: PropTypes.object,
  isClearable: PropTypes.bool,
  customFormatDate: PropTypes.func,
  alignment: PropTypes.oneOf(["right", "left"]),
};

export default Datepicker;
