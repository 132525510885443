import { AlphaStack, Button, ButtonGroup, Card, FormLayout, Page, Text } from '@shopify/polaris'
import {
	BankingInfo,
	BusinessType,
	Customer,
	Details,
	Items,
	ReasonForExemption,
	Totals,
} from "containers/DocumentTemplate/components"
import { t } from 'i18next'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from '../../../../components/Modal'
import ArrowLeftMinor from '../../../../img/arrow-left.svg'
import { DocumentType } from '../../../DocumentTemplate/common/constants'
import InlineText from '../../../DocumentTemplate/common/InlineText'
import { buildAddress, createEmptyLineItem } from '../../../DocumentTemplate/common/utils'
import ExemptionEditSaveBar from '../../../DocumentTemplate/components/ExemptionEditSaveBar'
import SalesTaxSelect from './SalesTaxSelect'
import VatNumberSelect from './VatNumberSelect'

const initialTemplateData = {
	line_items: [createEmptyLineItem()],
	invoice_date: moment(),
	due_date: moment().add(14, "days"),
};

const CreateExemption = ({
	data = {
		"total": "1.00",
		"tax_id": 14,
		"bank_id": 24,
		"currency": "EUR",
		"due_date": "31.10.2024",
		"subtotal": "1.00",
		"tax_rate": "0.00",
		"total_tax": "0.00",
		"line_items": [
			{
				"no": 1,
				"tax": true,
				"total": "1.00",
				"quantity": 1,
				"unit_price": "1.00",
				"description": "E-invoice receiving"
			}
		],
		"customer_id": 22,
		"sender_info": {
			"bank": "Canada",
			"iban": "12121213343",
			"email": "lalia_online@vatcompliance.co",
			"address": "Jarvevana tee, 9-40, Talin, 11314, Estonia",
			"company": "Lilia Test Company LTD",
			"bic_swift": "1111111111",
			"vat_number": "564654564",
			"country_code": "EST",
			"registration_code": ""
		},
		"invoice_date": "17.10.2024",
		"customer_info": {
			"email": "picadohome1@vatcompliance.co",
			"address": "Mumbai, G k Marg, Lower Parel, Italy",
			"company": "PICADO HOME DECORS PVT LTD",
			"vat_number": "IT15924991001",
			"country_code": "ITA",
			"registration_code": "263989652"
		},
		"vat_number_id": null,
		"invoice_number": "42542332432",
		"additional_name": "reverse charge",
		"sales_tax_number_id": 213
	},
	setData = () => {
	},
}) => {
	const [stepIndex, setStepIndex] = useState(0);
	const [goBackModalStatus, setGoBackModalStatus] = useState(false);
	const {user} = useSelector((state) => state.user);
	const navigate = useNavigate()
	const address = buildAddress(user);
	
	const goBack = () => {
		if (goBackModalStatus) {
			navigate(-1);
		}
	}
	
	const toggleGoBackModalStatus = () => {
		setGoBackModalStatus(!goBackModalStatus)
	}
	
	const onNavigateToTaxSettings = (tab) => {
		navigate("/tax-settings", {state: {defaultTab: tab}});
	};
	const onNavigateToProfile = () => {
		navigate("/profile");
	};
	const documentType = DocumentType.Exemption
	return (
		<Page
			separator
			fullWidth
			title={
				<div style={{display: 'flex', alignItems: 'center'}}>
					<div
						style={{
							marginRight: '10px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer'
						}}
					>
						<button
							onClick={toggleGoBackModalStatus}
							style={{
								backgroundColor: 'transparent',
								borderRadius: '50%',
								padding: '8px',
								border: '1px solid #637381',
								cursor: 'pointer',
							}}
						>
              <img
	              style={{cursor: 'pointer', marginTop: '2px'}}
	              src={ArrowLeftMinor}
	              alt={ArrowLeftMinor}
              />
            </button >
          </div >
          <Text
	          variant='heading3xl'
	          as='span'
          >
            Edit Certificate
          </Text >
        </div >
			}
		>
			{goBackModalStatus &&
				<Modal
					contentOnCenter
					title={t("vatReturns.warning")}
					iconType={"warning"}
					description={'Do you really want to go back? All data will be lost.'}
					onClose={goBack}
					visible={goBackModalStatus}
					footer={
						<ButtonGroup fullWidth distribution='center'>
	            <Button onClick={goBack} size='large'>
	              Yes
	            </Button>
	            <Button
		            primary
		            onClick={toggleGoBackModalStatus}
		            size='large'
	            >
	              No
	            </Button>
            </ButtonGroup>
					}
				/>
			}
			<div style={{height: '75vh', overflowY: 'auto'}}>
			<Card >
        <Card.Section title='Invoice Details'>
          <Details
	          data={data}
	          setData={setData}
	          documentType={documentType}
          />
        </Card.Section >
        <BusinessType
	        data={data}
	        setData={setData}
	        documentType={documentType}
        />
        <ReasonForExemption
	        data={data}
	        setData={setData}
	        documentType={documentType}
        />
        <Card.Section title='Invoice Sender'>
          <AlphaStack
	          spacing='4'
	          fullWidth
          >
            <SalesTaxSelect
	            documentType={documentType}
	            onLinkNavigate={onNavigateToTaxSettings}
	            data={data}
	            setData={setData}
            />
            <VatNumberSelect
	            documentType={documentType}
	            onLinkNavigate={onNavigateToTaxSettings}
	            data={data}
	            setData={setData}
            />
            <FormLayout >
              <InlineText
	              label='Registration code'
	              text={user.company.info.registration_code}
	              onLinkNavigate={onNavigateToProfile}
	              linkText={!user.company.info.registration_code && "required"}
              />
              <InlineText
	              label='Address'
	              text={address}
	              onLinkNavigate={onNavigateToProfile}
	              linkText={!address && "required"}
              />
              <InlineText
	              label='Email'
	              text={user.company.email}
	              onLinkNavigate={onNavigateToProfile}
	              linkText={!user.company.email && "required"}
              />
              <BankingInfo
	              data={data}
	              setData={setData}
              />
            </FormLayout >
          </AlphaStack >
        </Card.Section >
        <Card.Section title='Invoice Recepient'>
          <Customer
	          data={data}
	          setData={setData}
          />
        </Card.Section >
        <Card.Section title='Items'>
          <Items
	          data={data}
	          setData={setData}
          />
        </Card.Section >
        <Card.Section title='Totals'>
          <Totals
	          data={data}
	          setData={setData}
          />
        </Card.Section >
      </Card >
			</div>
				<ExemptionEditSaveBar
					data={data}
					setStepIndex={setStepIndex}
					documentType={documentType}
				/>
		</Page >
)
}

export default CreateExemption



