import { Badge, Checkbox } from '@shopify/polaris'
import React, { useState } from 'react'
import { getCurrencySymbol } from '../../../utils/currency'
import { formatMoney } from '../../../utils/numbers'
import useFetchDocuments from '../tabs/Invoices/hooks/useFetchDocuments'
import useSelectedRows from '../tabs/Invoices/hooks/useSelectedRows'
const invoiceTypes = {
	all: "all",
	invoice: "invoice",
	bill: "bill",
};

export default function GetColumnsEx(changeInOrOut) {
	const [query, setQuery] = useState({
		invoice_type: invoiceTypes.all,
		type: 'exemption-certificate',
		offset: 0,
		limit: 25
	});
	
	const { documents, loading, fetchDocuments } = useFetchDocuments(query)
	const { selectedRows, handleSelectAll, handleRowSelect } =
		useSelectedRows(documents);

	const columns = [
		{
			property: "checkbox",
			header: {
				label: (
					<Checkbox
						label=''
						checked={
							selectedRows.length > 0 &&
							selectedRows.length === documents.length
						}
						onChange={handleSelectAll}
					/>
				),
			},
			cell: {
				formatters: [
					(value, { rowData }) => (
						<Checkbox
							label=''
							checked={selectedRows.includes(rowData.id)}
							onChange={() => handleRowSelect(rowData.id)}
						/>
					),
				],
			},
		},
		{
			property: changeInOrOut === 'in' ? 'name' : 'name',
			header: { label: `${changeInOrOut === 'in' ? 'Buyer location' : 'Seller address'}` },
			cell: {
				formatters: [
					(value, { rowData }) => rowData.country.name
				],
			}
		},
		{
			property: "type_of_exemption",
			header: { label: "Exemption type" },
		},
		{
			property: "address",
			header: { label: "Exemption region" },
		},
		{
			property: "cert_number",
			header: { label: "Certificate" },
		},
		{
			property: "due_date",
			header: { label: "Expiration date" },
		},
	];
	return columns
}